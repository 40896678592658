import { reset } from 'styled-reset'
import { createGlobalStyle } from 'styled-components'
import { Text, spacing } from '@thg-commerce/gravity-theme'

export const GlobalStyle = createGlobalStyle<{
  additionalStyles?: string | string[]
}>`
  ${reset}

  article, aside, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  body {
    line-height: 1;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
    box-sizing: border-box;
    ${Text('bodyText', 'default')}
    min-height: 100vh;
  }

  *, *::before, *::after  {
    box-sizing: inherit;
  }

  #__next {
    min-height: 100vh;
  }

  h1 {
    ${Text('xlarge2', 'alternate')}
  }

  h2 {
    ${Text('xlarge1', 'alternate')}
    text-transform: none;
  }

  h3 {
    ${Text('large2', 'default')}
  }

  h4 {
    ${Text('large1', 'default')}
  }

  h5, h6 {
    ${Text('medium1', 'default')}
  }

  p, li, textarea, a {
    ${Text('bodyText', 'default')}
    margin: 0;
  }

  button {
    margin: 0;
    border: 0;
    padding: 0;
    background: transparent;

    &:not(:disabled) {
      cursor: pointer;
    }
  }

  ol, ul {
    list-style: none;
    margin: 0;
    padding: 0;
    text-indent: 0;
  }

  li {
    margin-top: ${spacing(1)};
  }

  ::-ms-clear, ::-ms-reveal { display:none; }

  ${(props) => props.additionalStyles}
`
